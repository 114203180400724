.app-mobius5-wrapper { 

  &.app-mobius5-wrapper-contact {
    @include media-breakpoint-down(md) {
      margin-bottom: 20px;
    }
  }
  
  @include media-breakpoint-down(md) {
    &.bg-white {
      background-color: transparent !important;
    }

    &.shadow {
      box-shadow: none !important;
    }
  }

  label {
    font-weight: normal;
  }

  .pristine-error {
    float: right;
    margin-right: 15px;
    font-size: 16px;
    text-align: right;
    width: 100%;

    display: block;
    margin-top: 5px;
    margin-bottom: 10px;
    color: #a94442;
  }

  .recaptcha { 
    overflow: hidden;
    clear: both;
  }

  .btn-send-mobius5-form {
    float: right;
    padding: 10px 20px;
  }

  textarea { resize: vertical; }
  .form-control.select-control { padding: 6px 7px; }
  .alert { width: 100%; }
}

.app-mobius5-field-required:after {
  content: "   *";
  font-size: 12px; 
  color: #a94442;
  display: inline-block;
  padding: 0 0 0 5px;
  vertical-align: top;
}

.app-mobius5-message { display: none; }


/* this will disable the areas that should be, but not the messages for example */
.app-mobius5-wrapper.disable .app-mobius5-support-disable { 
  pointer-events: none;
  opacity: 0.4;
}

.alert > p { font-size: 16px; }

.grecaptcha-badge { 
  visibility: hidden;
}

.app-mobius5-contactperson {
  .contact-data {
    text-decoration: underline;
  }
}

.newsletter-inputs {
  .form-group {
    flex: auto;
  }
  
  @include media-breakpoint-up(md) {
    > div {
      margin-left: 8px;
  
      &:first-child {
        margin-left: 0px;
      }
    }
  }
}

.content-socialmedia {
  svg {
    height: 30px;
    margin-right: 15px;
    path {
      fill: $primary!important;
      transition:fill .3s ease-in-out;
    }
    &:hover {
      path {
        fill: #FFF!important;
      }
    }
  }
}